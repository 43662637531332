import React, { useEffect } from 'react';
import StyledHeaderTabs from '../atoms/StyledHeaderTabs';
import { useSearchContext } from '../../contexts/SearchContext';
import useTabs from '../../hooks/useTabs';
import { SportEvents } from '../../@types/search';
import SearchTabPanel from '../atoms/SearchTabPanel';
import { Box } from '@mui/material';
import { StyleObj, TabConfigItem } from '../../@types';

const getTabData = (searchResults: SportEvents[], selectedTab: number) => {
  return searchResults.find((event) => event.sport.id === selectedTab);
};

const styles: StyleObj = {
  wrapper: {
    backgroundColor: 'background.default',
  },
  container: {
    flexDirection: 'column',
    width: '90%',
    margin: '10px auto',
    backgroundColor: 'background.default',
  },
};

const SearchResults = () => {
  const { searchResults, tabsConfig, displaySearchList } = useSearchContext();
  const [selectedSearchData, setSelectedSearchData] = React.useState<SportEvents | undefined>(undefined);

  const [selectedTab, setSelectedTab] = React.useState<number>();
  const handleChange = (selectedTab: number) => {
    setSelectedTab(selectedTab);
  };

  useEffect(() => {
    if (searchResults?.length) {
      setSelectedSearchData(getTabData(searchResults, selectedTab ?? searchResults[0].sport.id));
    } else {
      setSelectedSearchData(undefined);
    }
  }, [searchResults, selectedTab]);

  const tabProps = useTabs({
    tabConfig: tabsConfig as TabConfigItem<number>[],
    onChange: handleChange,
    defaultTab: tabsConfig[0]?.value,
  });

  return (
    <Box display={displaySearchList ? 'flex' : 'none'} sx={styles.wrapper}>
      <Box sx={styles.container}>
        {!!tabsConfig?.length && <StyledHeaderTabs tabConfig={tabsConfig} {...tabProps} />}
        <SearchTabPanel value={selectedTab} events={selectedSearchData?.events} />
      </Box>
    </Box>
  );
};

export default SearchResults;
