import { Tab, Tabs, TabsProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyleObj, TabConfigItem } from '../../@types';
import omit from 'lodash-es/omit';

const styles: StyleObj = {
  tabs: {
    minHeight: 0,
    '& .MuiTab-root': {
      minHeight: 0,
      minWidth: 0,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'info.main',
      color: 'neutral.700',
      height: '100%',
      borderRadius: '100px',
      transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    },
    '& .MuiTabs-flexContainer': {
      gap: 1.25,
    },
  },
  tab: (theme) => ({
    ...theme.typography.h6,
    fontSize: {
      xs: 11,
      md: 14,
    },
    textTransform: 'none',
    borderRadius: '100px',
    px: {
      xs: 0.75,
      md: 1.5,
    },
    py: {
      xs: 0.25,
      lg: 0.5,
    },
    color: 'inherit',
    '&.Mui-selected': {
      color: 'neutral.700',
      zIndex: 10,
      transition: 'color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    },
  }),
};

type StyledHeaderTabsProps<T> = TabsProps & {
  tabConfig: TabConfigItem<T>[];
  linkPrefix?: string;
  tabAsLink?: boolean;
};

const StyledHeaderTabs = <T,>({ tabConfig, tabAsLink = false, linkPrefix, ...props }: StyledHeaderTabsProps<T>) => (
  <Tabs {...omit(props, 'setValue')} variant='scrollable' scrollButtons='auto' sx={styles.tabs}>
    {tabConfig?.map((tab) => (
      <Tab
        key={tab.value as string}
        component={tabAsLink ? Link : 'div'}
        label={tab.label}
        value={tab.value}
        to={tabAsLink ? `${linkPrefix}/${tab.value}` : undefined}
        sx={styles.tab}
      />
    ))}
  </Tabs>
);

export default StyledHeaderTabs;
