import React from 'react';
import { IconButton } from '@mui/material';
import { useSearchContext } from '../../contexts/SearchContext';
import SearchIcon from '@mui/icons-material/Search';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  searchIcon: {
    color: 'info.300',
    '&:hover': {
      color: 'neutral.25',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
};

const Search = () => {
  const { showSearchBar, setShowSearchBar } = useSearchContext();
  const handleSearch = () => {
    setShowSearchBar(true);
  };
  if (showSearchBar) return null;
  return (
    <IconButton onClick={handleSearch} sx={{ marginLeft: 'auto', marginRight: '20px' }}>
      <SearchIcon sx={styles.searchIcon} />
    </IconButton>
  );
};

export default Search;
