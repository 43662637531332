import React from 'react';
import { Box, InputBase, IconButton, Button, alpha } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { StyleObj } from '../../@types';
import { useSearchContext } from '../../contexts/SearchContext';
import useLocalization from '../../hooks/useLocalization';

const styles: StyleObj = {
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '30%',
    left: '35%',
    '@media (max-width: 600px)': {
      width: '70%',
      left: '15%',
    },
  },
  search: {
    position: 'relative',
    borderRadius: '20px',
    flexGrow: 1,
    backgroundColor: (theme) => alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: (theme) => alpha(theme.palette.common.black, 0.25),
    },
  },
  searchIconWrapper: {
    padding: (theme) => theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputBase: {
    color: 'info.300',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '10px 10px 10px 0',
      // vertical padding + font size from searchIcon
      paddingLeft: 'calc(1em + 32px)',
      transition: 'width 300ms',
    },
    '&:focus': {
      color: 'info.300',
    },
  },
  closeButton: {
    fontSize: 10,
    padding: 0,
    color: 'info.300',
    '&:hover': {
      color: 'neutral.25',
      backgroundColor: 'transparent',
    },
  },
  iconButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'info.300',
    '&:hover': {
      color: 'neutral.25',
    },
  },
};

const SearchBar = () => {
  const { searchValue, setSearchValue, showSearchBar, setShowSearchBar } = useSearchContext();
  const {t} = useLocalization();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleDelete = () => {
    setSearchValue('');
  };

  if (!showSearchBar) return null;

  return (
    <Box sx={styles.searchWrapper}>
      <Box sx={styles.search}>
        <Box sx={styles.searchIconWrapper}>
          <SearchIcon />
        </Box>
        <InputBase
          sx={styles.inputBase}
          placeholder={t('search')}
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
          onChange={handleChange}
          autoFocus
        />
        {searchValue && (
          <IconButton sx={styles.iconButton} onClick={handleDelete}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Button sx={styles.closeButton} onClick={() => setShowSearchBar(false)}>
        {t('close')}
      </Button>
    </Box>
  );
};

export default SearchBar;
