import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../utils/api';
import { QUERY_KEYS } from '../constants';
import { SportEvents } from '../@types/search';
import { TabConfigItem } from '../@types';

interface SearchContextProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  searchResults: any;
  isLoading: boolean;
  isError: boolean;
  tabsConfig: TabConfigItem<number | undefined>[];
  displaySearchList: boolean;
  setDisplaySearchList: (value: boolean) => void;
  showSearchBar: boolean;
  setShowSearchBar: (value: boolean) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

interface SearchProviderProps {
  children: React.ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [displaySearchList, setDisplaySearchList] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [tabsConfig, setTabsConfig] = useState<TabConfigItem<number | undefined>[]>([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleTabsConfig = (data: SportEvents[]) => {
    const uniqueSports = Array.from(new Set(data.map((event) => event.sport)));
    const newTabsConfig = uniqueSports.map((sport) => ({
      label: sport.name,
      value: sport.id,
    }));
    setTabsConfig(newTabsConfig);
  };

  const {
    data: searchResults,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.searchResults],
    queryFn: (): Promise<SportEvents[]> => getData(`events/search?search=${searchValue}`).then((res) => res.items),
    enabled: false,
    onSuccess: (data) => {
      handleTabsConfig(data);
    },
  });

  useEffect(() => {
    if (searchValue.length > 2) {
      setDisplaySearchList(true);
      refetch();
    } else {
      setDisplaySearchList(false);
    }
  }, [searchValue, refetch]);

  const value = React.useMemo(
    () => ({
      searchValue,
      setSearchValue,
      searchResults,
      isLoading,
      isError,
      tabsConfig,
      displaySearchList,
      setDisplaySearchList,
      showSearchBar,
      setShowSearchBar,
    }),
    [searchValue, searchResults, isLoading, isError, tabsConfig, displaySearchList, showSearchBar]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export const useSearchContext = (): SearchContextProps => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
