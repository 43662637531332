import { Box, Divider, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { SearchEvent } from '../../@types/search';
import { useNavigate } from 'react-router-dom';
import { useSearchContext } from '../../contexts/SearchContext';
import useLocalization from '../../hooks/useLocalization';
import { StyleObj } from '../../@types';

interface SearchTabPanelProps {
  value?: number;
}

interface SearchTabPanelProps {
  value?: number;
  events?: SearchEvent[];
}

const formatDate = (date: string) => {
  return new Date(date).toLocaleString('en-GB', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const styles: StyleObj = {
  list: {
    p: 3,
    height: '100vh',
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  listItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:hover .hover-target': {
      color: 'info.300',
    },
  },
  mainListItemText: {
    mb: 2,
    '&:hover': { color: 'info.300' },
  },
  divider: {
    background: 'white',
    height: '1px',
    opacity: '0.2',
  },
};

const SearchTabPanel: React.FC<SearchTabPanelProps> = ({ value, events }) => {
  const navigate = useNavigate();
  const { setSearchValue, isLoading } = useSearchContext();
  const { t } = useLocalization();

  const handleClick = (searchEvent: SearchEvent) => {
    setSearchValue('');
    if (searchEvent.type === 'outright') {
      navigate(`/sports/${value}/tournaments/${searchEvent.id}/outrights`);
    } else if (searchEvent.isLive) {
      navigate(`/in-play/match-view/${searchEvent.id}`);
    } else {
      navigate(`/sports/events/${searchEvent.id}`);
    }
  };

  return (
    <List sx={styles.list}>
      {isLoading && <LinearProgress sx={{ width: '100%' }} />}
      {!events?.length && !isLoading && (
        <Box>
          <Typography variant='h6' textAlign='center'>
            {t('noSearchResults')}
          </Typography>
        </Box>
      )}
      {events?.map((event) => (
        <Box key={event.id} sx={styles.listItemWrapper} onClick={() => handleClick(event)}>
          <ListItem key={event.id} sx={styles.listItem}>
            <ListItemText
              primary={event.name}
              className='hover-target'
              primaryTypographyProps={{ fontWeight: 900 }}
              sx={styles.mainListItemText}
            />
            <ListItemText
              primary={`${event.competition.name}, ${event.tournament.name}`}
              primaryTypographyProps={{ fontSize: '0.7rem' }}
            />
            <ListItemText primary={formatDate(event.startDate)} primaryTypographyProps={{ fontSize: '0.7rem' }} />
          </ListItem>
          <Divider sx={styles.divider} />
        </Box>
      ))}
    </List>
  );
};

export default SearchTabPanel;
