import { IconButton, Typography } from '@mui/material';
import { useSanity } from '../../hooks/useSanity';
import { SanityImage, StyleObj } from '../../@types';
import { useEffect } from 'react';
import { getSanityImage } from '../../helpers/sanity';
import useBreakpoints from '../../hooks/useBreakpoints';
import { useNavigate } from 'react-router-dom';
import { useSearchContext } from '../../contexts/SearchContext';

type Props = {
  size?: 'small' | 'medium';
};

const setFavicon = (url: string) => {
  let link = document.getElementById('dynamic-favicon') as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = url;
};

const styles: StyleObj = {
  logo: {
    borderRadius: 2,
  },
};

const Logo = ({ size = 'medium' }: Props) => {
  const { data: logo } = useSanity<{ image: SanityImage }>('Logo');
  const sanitySrc = getSanityImage(logo?.[0]?.image);
  const storedSrc = localStorage.getItem('logoSrc') ?? '';
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();

  const { setShowSearchBar } = useSearchContext();

  const handleLogoClick = () => {
    if (isMobile) {
      setShowSearchBar(true);
    } else {
      navigate('/');
    }
  };

  const src = sanitySrc || storedSrc;

  useEffect(() => {
    setFavicon(src);
    if (sanitySrc) {
      localStorage.setItem('logoSrc', src);
    }
  }, [sanitySrc, src]);

  return (
    <IconButton onClick={handleLogoClick} sx={styles.logo}>
      {src ? (
        <img src={src} height={size === 'medium' ? '54px' : '32px'} alt='Logo' />
      ) : (
        <Typography variant={size === 'small' ? 'h3' : 'h1'} color='secondary'>
          LOGO
        </Typography>
      )}
    </IconButton>
  );
};

export default Logo;
